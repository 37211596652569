.contact-info
	font-weight: 300
	ul
		li
			display: flex
			gap: r(30px)
			@extend .body-2
			em
				+fz(24px)
				+flex-width(r(24px))
			& + li
				margin-top: r(44px)
				+mq-max(lg)
					margin-top: r(24px)

.contact-social
	ul
		display: flex
		gap: r(30px)
		margin-top: r(44px)
		+fz(24px)
		+mq-max(lg)
			margin-top: r(24px)
		li
			+flex-width(r(24px))

.contact-map
	iframe
		width: 100%
		height: r(360px)
