.global-footer
	padding: r(112px) r(30px) r(48px)
	+bg(secondary-100)
	+mq-max(lg)
		padding: r(40px) r(24px)
	.footer-item
		padding: r(54px) r(50px)
		+bg(primary-500)
		display: flex
		flex-direction: column
		justify-content: center
		+mq-max(lg)
			+text(white)
			padding: r(40px) r(24px)
	.footer-item-col
		& + .footer-item-col
			+mq-min(lg)
				border-left: 1px solid color(body)
			+mq-max(lg)
				border-top: 1px solid rgba(188, 99, 54, 0.86)

	.footer-social
		ul
			display: flex
			gap: r(20px)
			+fz(20px)

.footer-bottom
	padding: r(90px) 0 0 r(54px)
	border-top: 1px solid #000
	margin-top: r(48px)
	+mq-max(lg)
		padding: r(40px) 0 0

.subscribefrm
	display: flex
	gap: r(32px)
	input
		height: r(44px)
		border: 0
		border-bottom: 1px solid color(body)
		font-family: $font-heading
		+fz(21px)
		background: transparent
		width: r(308px)
		&:placeholder
			+fz(20px)
	button
		width: r(135px)
		height: r(44px)
		+bg(primary-500)
		+text(white)
		font-weight: 600
		+fz(16px)
		border: 0
