.home-1
	+mq-max(lg)
		padding: r(40px) 0
	.content-wrapper
		position: relative
		z-index: 1
		+mq-min(lg)
			margin-top: r(-120px)
			text-align: right
		.row
			+mq-min(lg)
				+gap-override(64px)
	.btn-link
		margin-top: r(64px)
		+mq-max(lg)
			margin-top: r(40px)
