.pages,.modulepager
	margin-top: r(32px)
	.pagination
		display: flex
		align-items: center
		justify-content: center
		li
			list-style: none
			display: inline-block
			text-align: center
			margin-left: r(8px)
			&:first-child
				margin-left: 0
			a,span
				justify-content: center
				align-items: center
				display: flex
				text-decoration: none
				width: r(40px)
				height: r(40px)
				font-size: r(16px)
				border: 1px solid color(primary-100)
				font-weight: 500
				+text(primary-500)
				+trans-all
			&.active,&:hover
				a,span
					+bg(primary-500)
					+text(white)
					border: 1px solid color(primary-500)

.no-pagination
	.pages
		display: none
