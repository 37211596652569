.global-header
	background: #F6F3F0
	border-bottom: r(3px) solid color(gray-200)

.header-top
	padding-top: r(18px)
	padding-bottom: r(8px)
	+mq-max(lg)
		align-items: center

.header-bottom
	padding-top: r(12px)
	border-top: 1px solid rgba(255, 255, 255, 0.20)

.logo
	width: r(55px)
	+mq-max(lg)
		width: r(80px)
	a
		display: block
		+res-ratio(55px, 46px)
		img
			object-fit: contain

.util-wrapper
	ul
		display: flex
		gap: r(32px)
		align-items: center
		+mq-max(lg)
			gap: r(24px)
		li
			a
				+fz(16px)
				+text(primary-500)
				text-transform: uppercase
				font-weight: 300
				+mq-max(lg)
					+fz(20px)

.language
	.current
		align-items: center
		display: flex
		gap: r(8px)
		text-transform: uppercase
		font-weight: 300
		+fz(16px)
		+text(primary-500)
		+mq-max(lg)
			gap: r(8px)
			+fz(20px)
		em
			+fz(14px)
	.dropdown-content
		min-width: auto
		+fz(14px)
		padding-top: r(12px)
		ul
			display: flex
			flex-direction: column
			gap: r(8px)
			padding: r(12px)

.header-top-right
	display: flex
	gap: r(24px)
	align-items: center
	height: 100%

.menu
	ul
		display: flex
		gap: r(24px)
		justify-content: space-between
		+mq-max(lg)
			flex-direction: column
			align-items: center
			gap: r(40px)
		li
			a
				@extend .body-2
				display: block
				padding-bottom: r(20px)
				position: relative
				+trans-all
				+mq-max(lg)
					font-size: r(32px) !important
					padding-bottom: r(12px)
				&::after
					+bg(secondary-950)
					content: ""
					top: 100%
					right: 0
					bottom: 0
					left: 0
					height: r(2px)
					position: absolute
					transform: scaleX(0)
					transform-origin: right
					transition: transform .3s ease-in-out
				+on-hover
					+text(primary-500)
					&::after
						transform: scaleX(1)
						transform-origin: left
			&.active
				a
					+text(primary-500)
					&::after
						transform: scaleX(1)
						transform-origin: left

.menu-toggle
	width: r(44px)
	height: r(44px)
	display: flex
	+flex-center
	padding: r(12px)
	+bg(primary-500)
	+text(white)

.menu-mobile
	position: fixed
	top: 0
	left: -100%
	width: 100vw
	height: 100%
	+bg(white)
	overflow: auto
	z-index: 102
	+trans-all
	&.show
		left: 0
	.menu-mobile-wrapper
		display: flex
		height: 100%
		flex-direction: column

	.close-menu
		position: absolute
		+box(44px)
		display: flex
		+flex-center
		+bg(gray-100)
		top: 0
		right: 0
		font-size: r(24px)
		z-index: 2
		&.close-mega
			right: auto
			left: 0
			width: auto
			padding: r(12px) r(8px)
			span
				font-size: r(20px)
				margin-left: r(8px)
	.menu-mobile-header
		display: flex
		align-items: center
		justify-content: center
		padding: r(40px) r(32px)
		+bg(secondary-100)
		.logo
			width: r(200px)

	.menu-mobile-body
		padding: r(48px) r(32px)
		+bg(secondary-50)
		flex: 1
		+content-margins
			padding-top: r(24px)
			margin-top: r(24px)
			border-top: 1px solid color(gray-100)

.backdrop
	+fill
	position: fixed
	opacity: 0.5
	+bg(black)
	z-index: 95
	display: none
