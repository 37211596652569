.heading-1
	+fz-lh(76px, 80px)
	+mq-max(lg)
		font-size: r(64px)

.heading-2
	+fz-lh(72px, 76px)
	+mq-max(lg)
		font-size: r(60px)

.heading-3
	+fz-lh(36px, 44px)
	+mq-max(lg)
		font-size: r(32px)

.heading-4
	+fz-lh(32px, 40px)
	+mq-max(lg)
		font-size: r(28px)

.heading-5
	+fz-lh(24px, 32px)

.body-1
	+fz-lh(20px, 28px)

.body-2
	+fz-lh(18px, 24px)

.body-3
	+fz-lh(16px, 24px)
	+mq-max(lg)
		+fz-lh-14(16px, 24px)

.body-4
	+fz-lh(14px, 20px)

.body-5
	+fz-lh(12px, 16px)

.font-heading
	font-family: $font-heading

.has-line
	position: relative
	&:before
		content: ''
		position: absolute
		bottom: 0
		+center(X)
		width: r(30px)
		height: 3px
		+bg(body)
	&-left
		&:before
			transform: none
			left: 0
