.form-group
	label
		@extend .body-3
		font-weight: 300
		display: inline-block
		margin-bottom: r(10px)
	+all-text-inputs
		width: 100%
		height: r(40px)
		border: 1px solid color(body)
		padding: 0 r(12px)
		+fz(16px)
		appearance: none
		background: transparent
		+mq-max(md)
			height: r(60px) !important
		+placeholder
			+fz(16px)
			+text(gray-500)
			opacity: 1
	select
		appearance: none
		background: transparent
		border: 1px solid color(body)
		height: r(40px)
		padding: 0 r(12px)
		+fz(16px)
		+select-bg
		+mq-max(md)
			height: r(60px) !important
	textarea
		height: r(88px) !important
		padding: r(12px) r(12px) !important
		resize: none
		+mq-max(md)
			height: r(160px) !important
	&.qradio,&.qcheckboxes
		ul
			display: flex
			gap: r(40px)
			flex-wrap: wrap
			+mq-max(md)
				gap: r(20px)
			li
				label
					display: flex
					@extend .body-3
					+text(gray-200)
					gap: r(12px)
					font-weight: 500
					&::before
						+icon("\f111")
				input
					+hide
					&:checked
						& ~ label
							+text(gray-500)
							&::before
								+icon("\f058")
								+text(primary-500)
	.RadPicker
		width: 100% !important
		position: relative
		table
			width: 100% !important
		.rcCalPopup
			position: absolute
			+center(Y)
			right: r(16px)
select
	width: 100%
	+bg(white)
	padding-right: r(44px)
	appearance: none
	padding-left: r(20px)
	border: 0
	background-repeat: no-repeat
	background-position: calc(100% - 1.04166667rem) 50%

.frm-btnwrap
	margin-top: r(20px)
	.label
		display: none

.frm-btn
	text-align: right
	input[type="submit"]
		+trans-all
		+bg(body)
		+text(white)
		border: 0
		height: r(44px)
		cursor: pointer
		+fz(18px)
		min-width: r(138px)
		font-weight: 600
		+trans-all
		+on-hover
			+bg(primary-500)
