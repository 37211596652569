.home-3
	+mq-max(lg)
		padding: r(40px) 0
	.col-left
		display: flex
		flex-direction: column
		justify-content: flex-end
		+mq-min(lg)
			padding-top: r(152px)
			padding-right: r(80px)
	.col-right
		+mq-min(lg)
			padding-bottom: r(110px)
			padding-left: r(20px)
		.btn-see-more
			margin-top: r(100px)
			+mq-max(lg)
				margin-top: r(24px)
