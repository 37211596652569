.section-coming-soon
	padding-left: r(24px)
	padding-right: r(24px)
	+mq-min(lg)
		padding-left: r(84px)
		padding-right: r(84px)
	.section-wrapper
		position: relative
	.img
		+mq-min(lg)
			width: (1109 / 1752) * 100%
	.content
		background: #F6F3F0
		display: flex
		+flex-center
		padding: r(32px)
		+mq-min(lg)
			position: absolute
			right: 0
			+center(Y)
			min-height: (240 / 342) * 100%
			width: (1024 / 1752) * 100%
