.location-detail-hero
	+mq-min(lg)
		margin: 0 r(-25px)
	figure
		+res-ratio(1030, 590)

.location-detail-content
	position: relative
	+mq-min(lg)
		&::before
			content: ''
			position: absolute
			top: 0
			left: r(-25px)
			width: (260 / 980) * 100%
			height: 100%
			+bg(secondary-100)
			z-index: -1
