.search-page
	padding: r(60px) 0
	+mq-max(lg)
		padding: r(40px) 0
	h1
		margin-bottom: r(20px)
		+fz-lh(40px, 48px)
		font-weight: 700
		color: #333333
		&.center
			text-align: center
	.btn-reindex
		display: none
	.searchcontrols
		.form-inline
			position: relative
			&:before
				position: absolute
				content: '\f002'
				font-family: "Font Awesome 6 Pro"
				top: 0
				right: r(10px)
				z-index: 1
				font-size: r(24px)
				+text(main)
				width: r(60px)
				height: r(60px)
				display: flex
				align-items: center
				justify-content: center
		.frm-btn,input[type="submit"]
			position: absolute
			top: 0
			right: r(10px)
			background-color: rgba(0,0,0,0)
			z-index: 2
			width: r(60px)
			height: r(60px)
			min-width: auto
	input[type="text"]
		width: 100%
		height: 100%
		padding-left: r(20px)
		padding-right: r(54px)
		border: 1px solid #dfe1e5
		background-color: #fff
		color: #333
		font-size: .875rem
		font-weight: 400
		height: r(60px) !important
		border-radius: r(30px) !important
		box-shadow: 0 0 0 0px rgba(0,0,0,0)
		transition: .25s cubic-bezier(.4,0,.2,1)
		outline: none
		&:focus
			box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28)
			border-color: rgba(223,225,229,0)
	input[type="submit"]
		border-radius: 2px
		user-select: none
		background-color: rgba(0,0,0,0)
		border: 0
		box-shadow: none
		cursor: pointer
		text-align: center
		display: flex
		align-items: center
		justify-content: center
		height: r(40px)
		outline: none
		width: r(44px)
		height: r(44px)
		border-radius: 50%
		font-size: 0
	.text-danger
		margin-top: r(20px)
		font-size: r(14px)
		color: #333
		font-style: italic
		font-weight: 600
	.searchresultsummary
		margin-bottom: 20px
	.searchresults
		margin-top: r(30px)
		.modulepager
			&:first-child
				display: none
	.searchresultlist
		margin-bottom: r(30px)
		> div
			+row(32px)
	.searchresult
		+flex-width(33.333333%)
		+mq-max(md)
			+flex-width(50%)
		.wrapper
			box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28)
			padding: 1rem
			border-radius: 0.5rem
			&:last-child
				margin-bottom: 0
			.search-img
				+res-ratio(1,1)
			.content
				margin-top: r(16px)
			h3
				font-size: r(18px)
				line-height: 1.33
				font-weight: 400
				margin-bottom: r(10px)
				a
					color: #1A0DAB
					text-decoration: none
					font-weight: 500
					&:hover
						text-decoration: underline
			.searchresultdesc
				color: #545454
				line-height: 1.54
				word-wrap: break-word
				font-size: small
				font-weight: 400
				margin-bottom: r(10px)
			.searchterm
				color: #6a6a6a
				font-weight: bold
				font-style: normal
				line-height: 1.54
				word-wrap: break-word
				font-size: small
			hr
				display: none !important
