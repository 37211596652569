.breadcrumb-wrapper
	+remove(lg, 'max')
	+bg(white)
	+text(gray-300)
	padding-top: r(20px)
	.breadcrumb
		display: flex
		align-items: center
		li
			z-index: var(--zIndex)
			position: relative
			a
				+fz-lh(14px, 20px)
				display: flex
				align-items: center
			& + li
				margin-left: r(-20px)
				a
					height: r(30px)
					padding: r(5px) r(20px) r(5px) r(40px)
					+bg(gray-50)
			+first(1)
				a
					font-size: 0
					+circle(40px)
					display: flex
					+flex-center
					+bg(primary-500)
					+text(white)
					&:before
						content: '\f015'
						font-family: 'Font Awesome 6 Pro'
						+fz-lh(16px,26px)
						font-weight: 300
