.home-4
	padding: r(120px) 0 r(96px)
	+mq-max(lg)
		padding: r(40px) 0
	.row
		+mq-min(lg)
			+gap-override(8px)

	.btn-see-more
		+mq-min(lg)
			margin-top: r(90px)
