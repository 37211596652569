.location-coming-soon
	&-hero
		position: relative
		+mq-min(lg)
			margin: 0 r(-25px)
		figure
			+res-ratio(1030, 590)
		.caption
			position: absolute
			top: 0
			left: 0
			width: 100%
			padding: 0 r(25px)
