$container-max-width: 96vw

// Big Container 
$container-padding: r(16px)
$container-3xl-width: r(1432px)
$container-2xl-width: 90vw
$container-xl-width: 88vw
$container-lg-width: 86vw
$container-md-width: 92vw
$container-sm-width: 94vw

// Small Container 
// $container-small-lg-width: 86vw
// $container-small-md-width: 92vw
// $container-small-sm-width: 94vw

$container-small-padding: r(15px)
$container-small-max-width: 96vw
$container-small-xl-width: r(1010px)

.container
	width: 100%
	max-width: $container-max-width
	padding-right: $container-padding
	padding-left: $container-padding
	margin-right: auto
	margin-left: auto
	+mq-min(sm)
		max-width: $container-sm-width
	+mq-min(md)
		max-width: $container-md-width
	+mq-min(lg)
		max-width: $container-lg-width
	+mq-min(xl)
		max-width: $container-xl-width
	+mq-min(2xl)
		max-width: $container-2xl-width
	+mq-min(3xl)
		max-width: $container-3xl-width

.container-sm
	width: 100%
	max-width: $container-max-width
	padding-right: $container-small-padding
	padding-left: $container-small-padding
	margin-right: auto
	margin-left: auto
	+mq-min(sm)
		max-width: $container-sm-width
	+mq-min(md)
		max-width: $container-md-width
	+mq-min(lg)
		max-width: $container-lg-width
	+mq-min(xl)
		max-width: $container-small-xl-width
