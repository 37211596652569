$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)

@each $color, $value in $button
	$hover-color: map-get($button-hover, $color)

	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, #333, #fff)
		+on-hover
			background-color: $hover-color
			color: contrast-color($hover-color, #333, #fff)
			border-color: $hover-color

	.btn-border-#{$color}
		border: 1px solid $value
		color: $value
		+on-hover
			background-color: $value
			color: contrast-color($value, #333, #fff)

// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.btn
	padding: r(12px) r(24px)
	display: inline-flex
	+trans-all
	+font(400,16px,24px)
	+flex-center
	gap: r(12px)
	span
		font-size: r(16px)
	&-pill
		border-radius: 9999px

.swiper-btn
	display: inline-flex
	+flex-center
	+box(44px)
	+text(primary-500)
	+border($color: primary-100)
	+bg(white)
	font-size: r(20px)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	+remove(lg,max)
	+on-hover
		+bg(primary-500)
		+text(white)
	&.swiper-button-disabled
		opacity: 0
		pointer-events: none
	&.no-box
		width: auto
		height: auto
		background: transparent
		+text(gray-300)
		+on-hover
			+text(primary-500)

.swiper-navigation
	&.is-between
		.swiper-btn
			position: absolute
			+center(Y)
			margin: 0 r(24px)
			&.swiper-prev
				right: 100%
			&.swiper-next
				left: 100%
			+mq-max(2xl)
				margin: 0 r(12px)

		&.is-no-gap
			.swiper-btn
				margin: 0
				&.swiper-prev
					right: auto
					left: 0
				&.swiper-next
					left: auto
					right: 0
	&.is-left
		display: flex
		gap: r(12px)

.btn-link
	font-style: italic
	display: inline-flex
	gap: r(20px)
	@extend .body-3
	align-items: center
	font-weight: 300
	em
		+trans-all
	+on-hover
		em
			transform: translateX(r(4px))

.btn-see-more
	display: inline-flex
	flex-direction: column
	gap: r(10px)
	+fz(14px)
	font-style: italic
	transition: 0.3s all
	.icon
		position: relative
		&::before
			content: url(../img/arrow-black.svg)
			transition: 0.3s all
		&::after
			content: url(../img/arrow-hover.svg)
			position: absolute
			top: 0
			left: 0
			width: 100%
			opacity: 0
			transition: 0.3s all
	+on-hover
		+text(primary-500)
		.icon
			&::before
				opacity: 0
			&::after
				opacity: 1
