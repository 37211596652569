.home-hero
	position: relative
	&-content
		position: absolute
		top: r(40px)
		left: 0
		width: 100%
		+text(white)
		+mq-max(lg)
			top: auto
			bottom: r(20px)

		.content
			margin-left: r(-20px)
			+mq-max(lg)
				text-align: center
