.location-nav-wrapper
	margin-top: r(24px)
	background: white
	padding: r(40px) 0 r(10px)
	ul
		display: flex
		justify-content: space-evenly
		gap: r(16px)
		li
			a
				@extend .body-2
				text-transform: uppercase
			&.active,&:hover
				a
					+text(primary-500)

.location-overview
	&-hero
		position: relative
		&-image
			figure
				+res-ratio(980,590)
		&-content
			position: absolute
			width: 100%
			left: 0
			bottom: r(18px)
			+text(white)
			.hero-title
				margin-bottom: r(54px)
				+mq-max(lg)
					margin-bottom: r(24px)
