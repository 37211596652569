.check-room-form
	.wrap-form
		display: flex
		flex-wrap: wrap
		gap: r(20px)
		.form-group
			flex: 1
			+mq-max(sm)
				flex: 1 1 100%
			label
				white-space: nowrap
				margin-bottom: r(4px)
			select,input
				height: r(30px)
				+bg(white)
				border: 0
				border-bottom: r(2px) solid color(body)
				+fz(14px)
				+mq-max(sm)
					height: r(40px)
			&-sm
				+mq-min(sm)
					+flex-width(r(84px))
	.frm-btnwrap
		margin-top: r(24px)
	.frm-btn
		text-align: center
		width: r(128px)
		margin: 0 auto
		position: relative
		&::before
			content: ""
			position: absolute
			height: r(28px)
			width: r(120px)
			background-image: url(../img/button-shadow.png)
			background-size: 100% 100%
			background-repeat: no-repeat
			background-position: center
			bottom: r(-14px)
			+center(X)
			z-index: 1
			left: 55%

		input[type="submit"]
			+bg(primary-500)
			height: r(32px)
			+fz(14px)
			font-weight: 400
			width: 100%
			box-shadow: -1px -1px 3px 0px rgba(0, 0, 0, 0.30), 1px 1px 3px 0px rgba(0, 0, 0, 0.30)
			position: relative
			z-index: 2
			+trans-all
			&:hover
				+bg(white)
				+text(primary-500)
			+mq-max(sm)
				height: r(60px)
				+fz(24px)
