.home-6
	.section-content
		min-height: r(624px)
		padding: r(112px) 0
		.content
			padding: r(42px) r(50px) r(60px)
			+bg-opa(white, 0.5)
			position: relative
			.btn
				position: absolute
				right: 0
				bottom: r(45px)
				border: 1px solid color(body)
