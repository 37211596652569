.home-5
	.item-list
		padding: 0 r(24px)
		display: flex
		justify-content: space-between
		gap: r(40px)
		position: relative
		+mq-max(sm)
			flex-wrap: wrap
		+mq-min(sm)
			&::before
				content: ''
				position: absolute
				top: 0
				bottom: 0
				+center(X)
				width: 1px
				height: 100%
				+bg(body)
				opacity: 0.5

		.item-col
			+flex-width(r(308px))
			+mq-max(lg)
				+flex-width(40%)
			+mq-max(sm)
				+flex-width(100%)
		.item
			&-content
				font-weight: 300
				.body-3
					line-height: (20/16)
			&-image
				figure
					+res-ratio(308, 242)
			.btn-see-more
				margin-top: r(40px)
				+mq-max(sm)
					margin-top: r(16px)
